<template>
    <div class="order-renew">
        <div>
            <h4>{{ $t('order_renew.title') }}</h4>
        </div>
        <button @click="$router.push('/search-products')" class="search-bottles">
            <img
                src="@/assets/svg/v2/registerProcess/search.svg"
                class="themed-img"
                alt="Search bottles"
            />
            <p>{{ $t('register.success.search') }}</p>
        </button>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    name: 'orderRenew',
    data() {
      return {
      }
    },
    async mounted() {
        await this.orderRenew(this.$route.params.key);
    },
    computed: {
      ...mapGetters({
      }),
    },
    methods: {
      ...mapActions({
        orderRenew: 'orderRenew'
      })
    }
  }
</script>
  
<style scoped lang="scss">
  @import '@/assets/styles/root.scss';

  .order-renew {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .search-bottles {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  padding: 5px 12px;
  margin-top: 15px;

  p {
    color: var(--subtitle-text-color);
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
  }
}

@media screen and (max-width: 500px) {

  .order-renew {
    margin: 20px 0;;
  }
}
  
</style>
  